<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form ref="form" v-model="isValid">
      <v-card-text>
        <v-text-field
          dense
          :label="
            getTranslation(
              'product.creationForm.formFieldTitles.lotNumber',
              '*'
            )
          "
          :rules="[
            () =>
              !!lotNumber.lNumber ||
              $t('product.creationForm.errors.lotNumberRequired'),
            !lotNumberExists ||
              $t('product.creationForm.errors.lotNumberExists'),
          ]"
          required
          v-model="lotNumber.lNumber"
          outlined
          :disabled="!!lotNumber.id"
          @keydown="verifyLotNumberUniqueness()"
        ></v-text-field>
        <v-menu
          v-model="menuEndDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          :disabled="canEditExpiryDate"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              append-icon
              v-model="lotNumber.expiryDate"
              :label="$t('product.creationForm.formFieldTitles.expiryDate')"
              :rules="[
                () =>
                  !!lotNumber.expiryDate ||
                  $t('product.creationForm.errors.expiryDateRequired'),
              ]"
              readonly
              outlined
              v-on="on"
              :disabled="!canEditExpiryDate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="lotNumber.expiryDate"
            @input="menuEndDate = false"
          ></v-date-picker>
        </v-menu>

        <div>
          <h2>Associated Countries</h2>
          <lot-number-country-detail
            class=""
            v-for="country in countries"
            :key="country.countryId"
            :value="country"
            :lotStatuses="lotStatuses"
          ></lot-number-country-detail>
        </div>
      </v-card-text>
    </v-form>
    <v-card-actions class="pr-4 d-flex">
      <v-spacer />
      <v-btn color="success" @click="save()" :disabled="isSending || !isValid">
        {{ $t("actions.save") }}
      </v-btn>
      <v-progress-circular v-if="isSending" indeterminate></v-progress-circular>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import LotNumberCountryDetail from "./lotNumberDetail/LotNumberCountryDetail.vue";
export default {
  components: { LotNumberCountryDetail },
  props: ["parameter"],
  data() {
    return {
      isValid: false,
      isSending: false,
      isShowContent: false,
      isLotNumberDataLoading: false,
      showSnackbar: false,
      snackBarMessage: "SAVED",
      showSnackbarColor: "success",
      lotNumber: {},
      menuEndDate: false,
      countries: [],
      lotNumberExists: false,
      validateUniquenessTimeout: null,
      lotStatuses: [],
    };
  },
  methods: {
    onSetTitle(title) {
      this.$emit("onSetTitle", title);
    },
    loadCountries() {
      this.isDataLoading = true;
      axios
        .get(
          this.$store.getters.getEnvironment +
            "lotNumber/" +
            this.parameter.id +
            "/details"
        )
        .then((response) => {
          this.countries = response.data.countries;
          for (let i = 0; i < this.countries.length; i++) {
            this.countries[i].countryName = this.$t(
              "country." + this.countries[i].countryCode
            );
          }
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
    load() {
      this.isDataLoading = true;
      axios
        .get(
          this.$store.getters.getEnvironment +
            "lotnumber/" +
            this.parameter.id +
            "?detailed=true"
        )
        .then((response) => {
          this.lotNumber = response.data;
          this.isDataLoading = false;
        });
    },
    save() {
      this.update()
        .then(() => this.updateDetails())
        .then(() => {
          this.close();
        });
    },
    insert() {
      this.isSending = true;
      this.lotNumber.productId = this.parameter.productId;
      axios
        .post(this.$store.getters.getEnvironment + "lotnumber/", this.lotNumber)
        .then((response) => {
          this.lotNumber.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setLotKey");
          this.close();
        })
        .catch((e) => {});
    },
    update() {
      this.isSending = true;
      return axios
        .put(
          this.$store.getters.getEnvironment + "lotnumber/" + this.lotNumber.id,
          this.lotNumber
        )
        .then((response) => {
          this.lotNumber.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setLotKey");
        })
        .catch((e) => {});
    },
    updateDetails() {
      this.isSending = true;
      return axios
        .put(
          this.$store.getters.getEnvironment +
            "lotNumber/" +
            this.lotNumber.id +
            "/details",
          this.countries
        )
        .then((res) => {
          this.$store.commit("setLotKey");
        })
        .catch((e) => {})
        .finally(() => {
          this.isSending = false;
        });
    },
    verifyLotNumberUniqueness() {
      if (this.validateUniquenessTimeout)
        clearTimeout(this.validateUniquenessTimeout);
      this.validateUniquenessTimeout = setTimeout(() => {
        this.isLotNumberDataLoading = this.$store.state.loadingBarColor;
        var xhr = axios.get(
          `${this.$store.getters.getEnvironment}lotNumber/count?lNumber=${this.lotNumber.lNumber}&exact=true&isActive=`
        );
        xhr
          .then((res) => {
            this.lotNumberExists = res.data.lotNumberCount > 0;
          })
          .catch((e) => {})
          .finally((_) => {
            this.isLotNumberDataLoading = false;
          });
      }, 500);
    },
    loadLotStatuses() {
      axios
        .get(this.$store.getters.getEnvironment + "lotStatus")
        .then((res) => {
          this.lotStatuses = res.data;
        })
        .catch((e) => {
          alert("Could not load the list of lot status");
          this.lotStatuses = [];
        });
    },
    close() {
      this.$store.commit("hideModalSecond");
    },
  },
  computed: {
    canEditExpiryDate() {
      return this.$store.getters.getIsAdmin && !!this.lotNumber.id;
    },
    canEditLotStatus() {
      return this.$store.getters.getIsAdmin;
    },
    lotStatusesData() {
      let statuses = this.lotStatuses;

      statuses.map((x) => {
        let translationKey = x.status
          .toLowerCase()
          .replace("/", "")
          .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
          });
        return (x.status = this.$t(
          `account.lotStatus.statuses.${translationKey}`
        ));
      });

      return statuses;
    },
  },
  created() {
    this.onSetTitle(this.$t("lotNumber.view.edit"));
    this.loadLotStatuses();
    if (this.parameter.id != -1) {
      this.load();
      this.loadCountries();
    }
  },
};
</script>
