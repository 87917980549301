<template>
  <v-card outlined class="my-4">
    <v-card-text>
      <v-row align="center" class="white--text" style="background: gray">
        <v-col cols="12" sm="12" md="4" lg="4" class="py-0">
          <h2>{{ lotCountry.countryName }}</h2>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" class="d-flex py-1 pr-1">
          <span style="min-width: 80px; padding-top: 10px">
            {{
              this.getTranslation(
                "product.creationForm.formFieldTitles.lotStatus",
                "*"
              )
            }}
          </span>
          <v-select
            class="white-select"
            v-model="lotCountry.status.statusId"
            :items="lotStatuses"
            item-text="status"
            item-value="id"
            outlined
            dense
            hide-details
            :disabled="!$store.getters.getIsAdmin"
          ></v-select>
        </v-col>
      </v-row>
      <!--       <div v-if="lotCountry.htcs.length == 0">
        <v-row>
          <v-col class="d-flex grey--text pt-7 pb-2">
            <v-spacer /> No HTC linked to this country.<v-spacer /> </v-col
        ></v-row>
      </div>
      <div v-if="lotCountry.htcs.length > 0">
        <v-row class="pt-2">
          <v-col class="d-flex"
            ><h2>Assiociated HTCs</h2>
            <v-spacer /><v-switch
              v-model="showAll"
              dense
              hide-details=""
              class="pt-0 mt-0"
              label="Show all"
            ></v-switch
          ></v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col class="pb-0">
            <div class="d-flex">
              <v-spacer />
              <span
                class="pt-3 grey--text"
                v-if="
                  !showAll &&
                    lotCountry.htcs.filter(
                      (x) =>
                        x.isSelectedOnLoad === true || x.isSelected === true
                    ).length == 0
                "
              >
                No HTC selected
              </span>
              <v-spacer />
            </div>
            <v-list class="pb-0">
              <v-list-item
                style="min-height: 0"
                v-for="htc in lotCountry.htcs"
                :key="htc.id"
              >
                <v-list-item-action
                  class="my-1"
                  v-if="showAll || htc.isSelected || htc.isSelectedOnLoad"
                >
                  <v-checkbox
                    hide-details=""
                    v-model="htc.isSelected"
                    :label="htc.htcName"
                    class="ellipsis"
                  >
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                <v-list-item-title>{{ htc.htcName }}</v-list-item-title>
              </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div> -->
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    lotStatuses: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      lotCountry: null,
      showAll: false,
    };
  },
  computed: {},
  watch: {
    lotCountry: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.lotCountry = v;
      },
    },
  },
  methods: {
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
  },
};
</script>
/* { country: { id: number, name: string }, lotStatus: { id: number, text:
string }, htcs: [ { id: number, name: string, isLinked: boolean } ] } */
