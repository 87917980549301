var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%","min-height":"100%"}},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","label":_vm.getTranslation(
            'product.creationForm.formFieldTitles.lotNumber',
            '*'
          ),"rules":[
          function () { return !!_vm.lotNumber.lNumber ||
            _vm.$t('product.creationForm.errors.lotNumberRequired'); },
          !_vm.lotNumberExists ||
            _vm.$t('product.creationForm.errors.lotNumberExists') ],"required":"","outlined":"","disabled":!!_vm.lotNumber.id},on:{"keydown":function($event){return _vm.verifyLotNumberUniqueness()}},model:{value:(_vm.lotNumber.lNumber),callback:function ($$v) {_vm.$set(_vm.lotNumber, "lNumber", $$v)},expression:"lotNumber.lNumber"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px","disabled":_vm.canEditExpiryDate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","append-icon":"","label":_vm.$t('product.creationForm.formFieldTitles.expiryDate'),"rules":[
              function () { return !!_vm.lotNumber.expiryDate ||
                _vm.$t('product.creationForm.errors.expiryDateRequired'); } ],"readonly":"","outlined":"","disabled":!_vm.canEditExpiryDate},model:{value:(_vm.lotNumber.expiryDate),callback:function ($$v) {_vm.$set(_vm.lotNumber, "expiryDate", $$v)},expression:"lotNumber.expiryDate"}},on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuEndDate = false}},model:{value:(_vm.lotNumber.expiryDate),callback:function ($$v) {_vm.$set(_vm.lotNumber, "expiryDate", $$v)},expression:"lotNumber.expiryDate"}})],1),_c('div',[_c('h2',[_vm._v("Associated Countries")]),_vm._l((_vm.countries),function(country){return _c('lot-number-country-detail',{key:country.countryId,attrs:{"value":country,"lotStatuses":_vm.lotStatuses}})})],2)],1)],1),_c('v-card-actions',{staticClass:"pr-4 d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":_vm.isSending || !_vm.isValid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")]),(_vm.isSending)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }